export const createReport = {
  heading: "Create Report",
  intro1: `To create a report you will need a project with annotations.`,
  intro2: "How to create a project",
  intro3: "How to create annotations",
  createReport: "Create a new report",
  step1:
    "In the project info view there is a reports tab.  When you select the tab there will be a <<<b>+</b>>> icon.  Click this to start creating a report.",
  step2: {
    heading: "Anatomy of a report",
    leftMenu:
      "This left menu will give you an index of all the annotations added to the report.",
    name: "The name of the report will show here",
    image:
      "The project thumbnail will be used here by default.  You can change this.",
    nameEdit: "Here you can edit the name of the report.",
    date: "Field to set the date of the report.",
    address: "If an address is set on the project it will display here.",
    description: "Set a description for the report.",
    assesment:
      "Set an assesment for the over all report.  You can add assesments per annotation as well.",
    organisation: "The organisation details will get displayed here.",
    addTopField: "Add a custom field to the top of the report.",
    addBotomFiled: "Add a custom field to the bottom of the report.",
    rightMenu: "The right side menu has tools for the report.",
    annotationArea: "The annotaitons will be shown here.",
    upload: "Button to uplaod a different image as the heading image.",
  },
  leftMenu: {
    heading: "Left menu",
    mapMenuTitle: "Map menu",
    mapsMenu: `The first button gives you options regarding the maps used on the report.
    You can toggle the overview map on/off,
    Toggle the maps on the annotations showing there position,
    and select which, if any, orthomosaic layer should be included in the maps.`,
    groupMenuTitle: "Group menu",
    groupMenu: `The second button lets you group the annotations.
    The options are None, Groups, Categories`,
    annotationIndexTitle: "Annotation index",
    annotationIndex: `This is the list of annotations in the report.  You can click on them to navigate to the annotation.  
    The colours to the right corresponds to the colour used for the annotation.`,
  },
  customFields: {
    heading: "Custom fields",
    step1: "Clicking the add field button will show you the following popup.",
    step2: "You can then give the field a name and add it to the report.",
    step3: "You will then be see the field added to the report.",
    step4:
      "Now you can enter any text value you want into the new field, or delete the field using the trash can icon.",
  },
  rightMenu: {
    heading: "Right menu",
    intro: "The right menu has the following options",
    preview: "Preview",
    previewDetails:
      "Shows an approximation of what the report will look like if you print it to pdf.",
    addAnnotations: "Add Annotations",
    addAnnotationsDetails:
      "Show all available annotations to add to the report.",
    save: "Save report",
    saveDetails: "Saves the report.",
    publish: "Publish report",
    publishDetails: `Publishes the report. Once the report is published it can no longer be edited.
      A report must be published to share it.`,
    delete: "Delete report",
    deleteDetails: "Deletes the report.",
    navigate: "Navigate to project",
    navigateDetails: "Navigates to the corresponding project.",
  },
  annotations: {
    heading: "Annotations",
    intro: `When clicking the add annotations button you will be met with the following popup.`,
    filter: "Filter available annotations",
    filterDetails:
      "You can filter the available annotations using the 3 dropdowns.(Categoreis, Groups, Tags)",
    annotationsList: "List of annotaitons",
    annotationsListDetails: `The right side list shows the available anotations that are not already added to the report.
    The right side will show what annotations will be added when you click the add button.
    You can click on the arrow to the right of them to add them to right list, or the arrows in the middle to add/remove all of the annotations.`,
    add: "Add the annotations",
    addDetails:
      "Clicking the add button will add the annotations to the report.",
    saveNote: "Remember to click the save button after adding the annotations.",
    copyNote: `A copy of the annotations gets added to the report.  This means that if you edit a annotation on a project
    it won't get updated on the report.  You will need to remove the annotation and add the updated one.
    This way you can have a history of annotations that live on reports.`,
    details: {
      heading: "Annotation details",
      intro: `This is what a annotation will look like on the report.`,
      pdfNote:
        "If a PDF is attached to the annotation, it will be added to the bottom of the report.",
      description: `All fields that was filled on the annotation will be displayed on the report.
      This includes the desciption, measurement details, tags, severity/consequence,
      all custom fields, image attachments and a map showing the location.`,
      delete: "Remove annotation",
      deleteDetails: `You can click the trash can icon in the top right corner, next to the name of the annotation
        to remove it from the report`,
      assesment: "Annotation assesment",
      assesmentDetails:
        "You can add an asesment to every individual annotation.",
    },
  },
  uploads: "Upload image",
};
