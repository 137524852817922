export const mapIcons = {
  heading: "Map Icons",
  stepOne: `This button closes or opens the right side menu.`,
  stepTwo1: `This icon gives you the option to draw lines on the Map to measure a section
  i.e. Here we measure the parking lot and on the label you can see the measurement in (M) Meters`,
  stepTwo2: `1.Click on the Icon on the left indicated on the image below.
  2.Postion the mouse cursor on where you want to start the line and click once.
  3.Drag the cursor to the second point you want to mark, click again.
  4.Do this until you are done, when you want to stop click on the last point you made again to finish the action.`,
  stepThree: `This icon is to draw a new polygon, the purpose of this action is to get the measurement in square meter.
  Note: This will work the same why as the one line measurement action`,
  stepFour: `This location icon is used to mark a location on the map.
  To use this action click on the icon and click on the map where you want to mark the location.`,
  stepFive: `These two icons help you to zoom in and out,
  To zoom OUT use the magnofying glass with the (-)
  To zoom IN use the magnofying glass with the (+)
  There is a 3rd option to zoom in and out and that is scrolling the mouse wheel up and down.`,
  stepSix: `2D and 3D buttons navigates you to the different dimensions`,
  figure1: `2D (2 Dimensional) view`,
  figure2: `3D (3 Dimensional) view. Learn more about how to use the 3D model here (Coming Soon)`, //Vra zander
  info1: `Please look out for arrows like these on the pictures`,
  // howTo1:`1.When drawing the line,click on the Icon then position the mouse curser on where you want to start the line,
  // Then click once, `
};
