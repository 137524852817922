export const login = {
  heading: "Login",
  stepOne: `On the login page you will find two text boxes.`,
  stepTwo: `Please fill in your correct credentials i.e Username/Email & Password.`,
  stepThree: `Click Sign In`,
  stepFour: `If you get Invalid username or password, click on forgot password`,
  stepFive: `Fill in your Username/Password and Submit, we will send you instructions on how to create a new password.`,
  stepSix: `If you get the following page and refresh does not work, please close the site and open the following link.`,
  stepSixLinkName: `Home`,
  cantLogin: "Can't login?",
};
