export const folders = {
  heading: "Folders",
  stepOne: `Folders`,
  stepTwo: `Create a folder`,
  stepThree: `Give your folder a name and click create`,
  stepFour: `Click on the ellipsis to access the options on the right`,
  stepFive: `In this menu there is a few items.
  1. The name of the folder
  2. Current users you are sharing the folder with
  3. Add more users by clicking the add button
  4. Delete a user by clicking on the bin icon`,
  stepSix: `The next few icons does the following
  1. Moves the folder to another folder of your choosing
  2. Edit the name of the folder
  3. Delete the folder`,
  stepSeven: `By selecting "SHARE" on a folder, you have the option to enter an email of who you want to share the foleder with then give them either "Read/Write or Admin role and then also an Expires date.".`,
  stepEight: `By selecting "DELETE" on a folder, you have the option to remove the folder from your account.`,
  stepNine: `By selecting the move option you can choose the folder you would like to move the folder to`,
  stepTen: `By selecting the edit option you can change the name and then click the save icon to save the new folder name`,

  someMoreDetails:
  "On the platform you can structure your Projects in folders to navigate and find them easier.",
  someMoreDetailsNoteOne:
    "NOTE: The folder you create will appear in the folder you are currently in, i.e. If you have a folder A and B and you want a new folder in B, you have to navigate into folder B and then create the folder.",
  someMoreDetailsNoteTwo:
    "NOTE: You also have the option to remove the expire date, Click the x on the expires tab.",
  

  settings: "Folder Options",
};
