export const annotations = {
  heading: `Annotations`,
  stepOne: `For all annotations you have the following options.
  1. Close menu
  2. Edit
  3. Delete`,

  stepTwo: `Show segmants helps you get more information on the annotation on the map.
  i.e. When you have a polygon shape, it will show you the line distance of all the sides.
  So instead of the Square meters view, it will show you the meters of each line contributing to the square meters.`,

  stepThree: `Here you can upload a images taken of the location of the annotation.
  Simply drag your items on the white bar and click upload.`,

  stepFour: `When you click on edit you get a lot of items to work with.`,

  stepFive: `First up we have the name you can edit.`,

  stepSix: `The drop icon lets you choose a colour for the annotation on the map.`,

  stepSeven: `Here you can add a description or notes of the annotations.`,

  stepEight: `Add your annotation to a group to organise them. (LINK COMING SOON)`, //TODO: add link

  stepNine: `Set the categories for the annotation. (LINK COMING SOON)`, //TODO: add link

  stepTen: `Publicly visible option gives you the option to share your annotation with everyone that has access to the same project.`,

  stepEleven: `Adding a field you have two options (Text or Date).
  Option one is a text field. You can add any extra data you want onto the annotation.
  Option two is a date field. Use this to specify any date related information you want to add i.e. Date captures, due date for work, expiry date`,

  stepTwelve: `Delete the fields by clicking on the bin icon.`,

  stepThirteen: `Add tags to your annotation to give brief idea's of what this annotation is, you can have more then one.`,

  stepFourteen: `Severity and consequence goes together.
  The severity is how bad the issue is in the consequence is the impact it will have if it does not get fixed.`,

  info1: `Please look out for arrows like these on the pictures.`,

  rightMenu: `Right side menu`,

  editingAnnotations: `Edit Annotation`,

  groups: `Groups`,

  categories: `Categories`,

  settings: `Settings`,

  fields: `Custom fields`,

  tags: `Tags`,

  severityConsequence: `Severity and consequence`,
};
