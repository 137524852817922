<script setup lang="ts">
import { onMounted } from "vue";
import { useI18n } from "vue-i18n";

onMounted(() => {});

const { t } = useI18n();
</script>

<template>
  <h1>{{ t("shared.home") }}</h1>

  <ul class="left">
    <li>
      <h6>{{ t("sideMenu.section1.heading") }}</h6>
      <ul>
        <li>
          <a href="/#login">{{ t("sideMenu.section1.part1") }}</a>
        </li>
        <li>
          <a href="/#folders">{{ t("sideMenu.section1.part2") }}</a>
        </li>
        <li>
          <a href="/#projects">{{ t("sideMenu.section1.part3") }}</a>
        </li>
      </ul>
    </li>
    <li>
      <h6>{{ t("sideMenu.section2.heading") }}</h6>
      <ul>
        <li>
          <a href="/#projectsIntro">{{ t("sideMenu.section2.intro") }}</a>
        </li>
        <li>
          <a href="/#generalMenuIcons">{{ t("sideMenu.section2.part2") }}</a>
        </li>
        <li>
          <a href="/#mapIcons">{{ t("sideMenu.section2.part1") }}</a>
        </li>
        <li>
          <a href="/#annotations">{{ t("sideMenu.section2.part3") }}</a>
        </li>
      </ul>
    </li>
    <li>
      <h6>{{ t("sideMenu.section3.heading") }}</h6>
      <ul>
        <li>
          <a href="/#reportingintro">{{ t("sideMenu.section3.part1") }}</a>
        </li>
        <li>
          <a href="/#CreateReport">{{ t("sideMenu.section3.part2") }}</a>
        </li>
      </ul>
    </li>
  </ul>
</template>

<style scoped>
.left {
  width: 100%;
}
</style>
