export const organization = {
    heading: "Organization",
    stepOne: `Click on the profile Icon and choose organization.`,
    stepTwo: `On this page you will see all your organizations`,  
    stepThree: `Create your an organization by clicking on the +`,
    stepFour: `Choose a name for the organization.`,
    stepFive: `Choose a image (A logo or photo).`,
    stepSix: `Click Create.`,
    stepSeven: `When created choose the edit button on the right`,
    stepEight: `When you selected edit you will see the following screan where you can edit (Details / Projects / Users / Catagories / Tags / Groups).`,

    detailsHeading:`Details`,
    stepNine: `On details you can edit the image, Name, Address, Primary and Secondary colours`,
    stepTen: `When you are happy with your editing you can now safe the details by clicking on the check mark icon, If you want to discard or cancel your changes click on the X`,

    projectsHeading:`Projects`,
    stepEleven: `On projects you can add projects to your organization with this add button`,
    stepTwelve: `Follow the steps 1-3,
    1. Click the drop down to see the projects
    2. Choose a project by clicking on one
    3. click add`,
    stepThirteen: `When the project is added, you will see the following
    1. The name of the project
    2. When last it has been updated
    3. When it has been created
    4. The option to delete the project from your organization.`,

    usersHeading:`Users`,
    stepFourteen: `You can add users and there roles by clicking the add button`,
    stepFiveteen: `Type in there email, choose what permissions you would like to give the user, permissions intail.
    Read - If you want the user to only have the option to view the project.
    Write - If you want the user to make changes to the project.
    Admin - If you want the user to have more then read and write but also share & delete the project
    Then click Add`,
    stepSixteen: `When you added a user you can see the following
    1. The email of the user
    2. The Name
    3. The Last name
    4. And then most important the role`,

    categoriesHeading:`Categories`,
    stepSeventeen: `Categories can be added to the organization buy clicking add.
    1. Give a reference
    2. Give a Name 
    3. Choose a Parent the category should fall under
    4. Click Save `,
    stepEighteen: `When created you can no see your Category, also have more options
    1. Edit Category
    2. Delete Category`,

    tagsHeading:`Tags`,
    stepNineteen: `Tags can be set up here so you cn use them in the project to help you find annotations or lable an item.
    Click the add Tag button here.
    1. Give the name of the tag
    2. Click save`,
    stepTwenty: `After you saved, the tag will be avalible to use in the projects added to the organization.
    i.e : Here I made an Annotation and then when I edit the annotation i get the option to add the tag.
    1. Annotation (Drawn on the map)
    2. Choose the tag made in the organization.`,
    
    groupsHeading:`Groups`,
    stepTwentyone: `The same as the tags you can add a group by clicking on the add button`,
    stepTwentytwo: `When the add group popup shows add a group name and click save`,
    tepTwentyThree: `The group can now be used by editing an Annotation/ cluster/ Scan ect.`,
    tepTwentyFour: `Select which folders you would like to add to the shared link and click create link`,
    tepTwentyFive: `To view the link click on the clipboard icon to copy the link then paste the link in a new browser window`,
  
    someMoreDetails: 'On the platform you can structure your Projects in folders to navigate and find them easier.',
    someMoreDetailsNoteOne: 'NOTE: The folder you create will appear in the folder you are currently in, i.e. If you have a folder A and B and you want a new folder in B, you have to navigate into folder B and then create the folder.',
    someMoreDetailsNoteTwo: 'NOTE: You also have the option to remove the expire date, Click the x on the expires tab.',
    someMoreDetailsNoteThree: 'NOTE: The project you create will appear in the folder you are currently in, i.e. If you want your project to be in folder A, navigate to folder A and create a project.',
    someMoreDetailsNoteFour: 'NOTE: The project you create will appear in the folder you are currently in, i.e. If you want your project to be in folder A, navigate to folder A and create a project.',
    someMoreDetailsNoteFive: 'NOTE: The project you create will appear in the folder you are currently in, i.e. If you want your project to be in folder A, navigate to folder A and create a project.',
  };
  