export const sideMenu = {
  section1: {
    heading: "Something",
    part1: "Something more",
    part2: "Something else",
    part3: "Something diferent",
  },
  section2: {
    heading: "Section 2",
    part1: "Something more",
    part2: "Something else",
    part3: "Something diferent",
  },
  section3: {
    heading: "Section 3",
    part1: "Something more",
    part2: "Something else",
    part3: "Something diferent",
  },
};
