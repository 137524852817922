export const projectFolders = {
    heading: "Project Folders",
    stepOne: `To access the project folders open any project`,
    stepTwo: `Then you will find a folder icon on the top right`,
    stepThree:`When you get to the project folders you have a few options,but if you want to go back to the project map click the map icon.`,
    
    addFolderHeading:`Add Folder`,
    addFolder:`There is two buttons to add folders To add a folder click on the Add folder text here.`,
    addFolder2:`Type in the name of the folder and click create.`,
    
    addFilesHeading:`Add Files`,
    addFiles1: `To add files, click on the folder you want the file to be in and click Add Files.`,
    addFiles2: `To add files drag and drop the files on the white bar, click Upload`,
    
    editfolderheading:`Edit`,
    EditFolder: `To edit folder click on the elipsis and select edit folder`,
    EditFolder2: `Rename the folder and click on the save icon`,


    moveFolderHeading:`Move`,
    moveFolder: `To move a folder click on the elipsis and click move.`,
    moveFolder2: `Choose where you want to move the folder and click save.`,
    moveFiles1:`To move files select files click on the elipsis on the right and choose move`,

    downloadHolderHeading:`Download`,
    download: `To download the folder click the elipsis and click download
    The download will download as a Zip file.`,
    download2: `Download a file by clicking on the down arrow Icon of the file.`,
    downloadLocation:`When downloading a file or folder the downloaded items can be found in the downloads folder of your pc`,

    generatePhotoClusterHeading:`Generate photo cluster`,
    generatePhotoCluster: `To create a photo cluster, go to the folder with the images, then click on the elipsis and choose "Generate Photo cluster"`,
    generatePhotoClusterInfo:`If the images has location data ON, when clicking generate photo cluster a photo cluster layer will be created on the map using that photo's location data
    If you go to the map and put the Cluster ON you will see each photo number on the map showing the location of that image taken.`,


    deleteHeading:`Delete`,
    deleteWarning:`When deleting a folder, it will delete everyting in the folder includding other folders and files.`,
    Delete: `To delete a folder click the elipsis and click delete`,
    deleteSingleFile:`To delete a single file click on the bin of the file you want to delete`,
    deleteMultiFiles:`To delete multiple files select the the files and click on the elipsis then click on  delete selected files`,

    documentsViewChangeHeading:`Change documents view.`,
    documentsViewChange: `To change the view of the files simply navigate to the folder and click on the icon next to the elipsis`,    

    createPOIHeading:`Point of interest (POI)`,
    WhatIsPOI:`What is POI: This is a feature that can help you set markers/annotations on the project map and add files to the relevent marker,
    i.e: If you have photos of the parking lot you have to work on or change you can select the phots and create a POI and mark the parking lot on the map.`,
    createPOI: `To Create a POI (Point of interest) Select the files reletive to the POI and click the elipsis and create POI`,
    createPOI2: `1. Choose the name of the annotation
    2. Put in a description
    3. Choose where on the map is the POI
    4. Click save`,   


    downloadSelectedFiles: `With "Share" you can Either share via Email or Link.`,
  };
  