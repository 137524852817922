export const generalMenuIcons = {
  heading: `General Menu Icons`,
  stepOne: `Click this icon to see more information about the project on the right side.`,

  stepTwo: `This icon gives you 3 options,
  1. Add layer lets you add a layer on the map by uploading a Geotiff file type, see this link to find out more (COMING SOON)
  2. Add new layer cloud,lets you add the 3D model file to view the map in 3D
  3. Add matterport link, lets you embed a link to a matterport internal scan`, //TODO: Help  met link en check dalk of die bewoording reg is vir die 3 Buttons veral nr 2

  stepThree: `With this icon you can toggle the settings of putting the labels ON or OFF on the map.`,

  stepFour: `This X closes the left side menu.`,

  stepFive: `Clicking the back arrow next to the project name in the top bar will take you back to the previous screen.`,

  stepSix: `This small arrows next to items in the right side menu will expand or collapse any items under it.
  When the arrow points to the right it is closed.
  When the arrow points downwards it is open.`,

  stepSeven: `When the checkbox next to an item is not checked the item will be hidden on the map.
  When the checkbox is checked the item is present on the map.`,

  stepEight: `This icon is very helpfull when you have a lot of annotation, markers or layers and you loose track of them all.
  You can simply click on the icon of the one you are looking for on the map and it will take you to where it is located.`,

  stepNine: `You can adjust the size of the side menu.
  Put you mouse cursor on the icon, then click and drag to the left or right.`,

  stepTen: `Click to change the language.`,

  stepEleven: `Here you have 3 options.
  1. See your profile.
  2. Go to your organisations.
  3. Log out of the App`,

  stepTwelve: `This will open your folders to store documents like pictures or PDF's.
  This icon is only available when viewing a project`,

  stepThirteen: `If you see this icon pop up, you are busy uploading a file.
  Below you will see
  1. Cancel all button to cancel the upload.
  2. The amount of files.
  3. The name of the file.
  4. The upload speed.
  `,

  info1: `Please look out for arrows like these on the pictures.`,

  topBar: "Top bar icons",
  rightMenu: "Right side menu",
  // howTo1:`1.When drawing the line,click on the Icon then position the mouse curser on where you want to start the line,
  // Then click once, `
};
