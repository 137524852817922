<script setup lang="ts">
import { onMounted, toRefs } from "vue";
import { MDCList } from "@material/list";
import { MDCRipple } from "@material/ripple";

const props = defineProps<{
  name: string;
  selected?: boolean;
  location: string;
}>();

const { selected } = toRefs(props);

onMounted(() => {
  const listElements = document.querySelectorAll(".mdc-list");

  if (listElements && listElements.length > 0) {
    for (const listElement of listElements) {
      const list = new MDCList(listElement);
      list.listElements.map((listItemEl: any) => new MDCRipple(listItemEl));
    }
  }
});
</script>

<template>
  <a
    class="mdc-list-item"
    :class="{ 'mdc-list-item--activated': selected }"
    :href="location"
    aria-current="page"
  >
    <span class="mdc-list-item__ripple"></span>
    <span class="mdc-list-item__text">{{ name }}</span>
  </a>
</template>

<style scoped lang="scss">
@use "@material/list/evolution-mixins" as list-evolution-mixins;

@include list-evolution-mixins.core-styles;
</style>
