export const projects = {
    heading: "Projects",
    stepOne: `Create/Add a project.`,
    stepTwo: `Give your project a name.`,
    createProject1:`Choose an organization that this project belongs to.
    Organization can be found here (Link here)`,
    createProject2:`Projection is a piece of the world map being used as shownbelow, Read all about it here.(Link here)`,
    createProject3:`If you have a base map of your area, you can select it here, if not use the Open Street Maps.
    Using your specific area will be more accurate.`,
    stepThree: `Choose a file from your device by Draging the file to the white box OR click on the white box to go to your device files and select the file you want to upload.`,
    stepFour: `Choose the piece you would like to use with the box sizer.`,
    stepFive: `If you did all the above click Save.`,
    stepSix: `Your project will live here.`,
    stepSeven1: `If you want more information on the "Project" Click this`,
    stepSeven2: `you will then get more options on the right hand side i.e Edit,Delete,Share and more`,
    stepEight: `The name, image, and address for the project can be edited by clicking on this icon.`,
    stepNine: `After you made your changed you can click this icon to save your changes`,
    stepTen: `This Icon will give you the option to move the project to another folder`,
    stepEleven: `Choose the folder you want to move it to and click save`,
    stepTwelve: `To delete the project click the bin Icon`,
    stepThirteen: `With "Share" you can Either share via Email or Link.`,
    stepFourteen: `Share via email by clicking on this button below`,
    stepFiveteen: `Enter the email / person you would like to send the project to`,
    stepSixteen: `Next choose what permissions you would like to give the user.
    Read - If you want the user to only have the option to view the project.
    Write - If you want the user to make changes to the project.
    Admin - If you want the user to have more then read and write but also share & delete the project`,
  stepSeventeen: `Pick a date on when the project should expire.
    Note: You can leave the date empty so that the project dont expire.`,
  stepEighteen: `You can find all the emails shared information below.`,
  stepNineteen: `You can delete an entry by clicking on the bin icon.`,
  stepTwenty: `To create a shared link click here 1st `,
  stepTwentyone: `Choose the name of the link`,
  stepTwentytwo: `Next choose what permissions you would like to give the user.
    Read - If you want the user to only have the option to view the project.
    Write - If you want the user to make changes to the project.
    Admin - If you want the user to have more then read and write but also share & delete the project`,
  tepTwentyThree: `Choose the expiry date`,
  tepTwentyFour: `Select which folders you would like to add to the shared link and click create link`,
  tepTwentyFive: `To view the link click on the clipboard icon to copy the link then paste the link in a new browser window`,

  someMoreDetails:
    "On the platform you can structure your Projects in folders to navigate and find them easier.",
  someMoreDetailsNoteOne:
    "NOTE: The folder you create will appear in the folder you are currently in, i.e. If you have a folder A and B and you want a new folder in B, you have to navigate into folder B and then create the folder.",
  someMoreDetailsNoteTwo:
    "NOTE: You also have the option to remove the expire date, Click the x on the expires tab.",
  someMoreDetailsNoteThree:
    "NOTE: The project you create will appear in the folder you are currently in, i.e. If you want your project to be in folder A, navigate to folder A and create a project.",
  someMoreDetailsNoteFour:
    "NOTE: The project you create will appear in the folder you are currently in, i.e. If you want your project to be in folder A, navigate to folder A and create a project.",
  someMoreDetailsNoteFive:
    "NOTE: The project you create will appear in the folder you are currently in, i.e. If you want your project to be in folder A, navigate to folder A and create a project.",
};
