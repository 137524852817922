<script setup lang="ts">
import { useI18n } from "vue-i18n";
import MenuListItem from "@/components/layout/MenuListItem.vue";
import { useRoute } from "vue-router";

const route = useRoute();

const { t } = useI18n();
</script>

<template>
  <nav class="mdc-list">
    <hr class="mdc-list-divider" />
    <h3 class="mdc-list-group__subheader">
      {{ t("sideMenu.section1.heading") }}
    </h3>
    <ul>
      <MenuListItem
        :name="t('sideMenu.section1.part1')"
        location="/#login"
        :selected="route.name === 'Login'"
      />
      <MenuListItem
        :name="t('sideMenu.section1.part2')"
        location="/#folders"
        :selected="route.name === 'Folders'"
      />
      <MenuListItem
        :name="t('sideMenu.section1.part3')"
        location="/#projects"
        :selected="route.name === 'Projects'"
      />
    </ul>

    <hr class="mdc-list-divider" />
    <h3 class="mdc-list-group__subheader">
      {{ t("sideMenu.section2.heading") }}
    </h3>
    <ul>
      <MenuListItem
        :name="t('sideMenu.section2.intro')"
        location="/#projectsIntro "
        :selected="route.name === 'ProjectsIntro'"
      />
      <MenuListItem
        :name="t('sideMenu.section2.part1')"
        location="/#mapIcons "
        :selected="route.name === 'MapIcons'"
      />

      <MenuListItem
        :name="t('sideMenu.section2.part2')"
        location="/#generalMenuIcons "
        :selected="route.name === 'GeneralMenuIcons'"
      />

      <MenuListItem
        :name="t('sideMenu.section2.part3')"
        location="/#annotations"
        :selected="route.name === 'Annotations'"
      />

      <MenuListItem
        :name="t('sideMenu.section2.part4')"
        location="/#Project-Folders"
        :selected="route.name === 'Project-Folders'"
      />
    </ul>

    <hr class="mdc-list-divider" />
    <h3 class="mdc-list-group__subheader">
      {{ t("sideMenu.section3.heading") }}
    </h3>
    <ul>
      <MenuListItem
        :name="t('sideMenu.section3.part1')"
        location="/#ReportingIntro"
        :selected="route.name === 'ReportingIntro'"
      />
      <MenuListItem
        :name="t('sideMenu.section3.part2')"
        location="/#CreateReport"
        :selected="route.name === 'CreateReport'"
      />
    </ul>

    <hr class="mdc-list-divider" />
    <h3 class="mdc-list-group__subheader">
      {{ t("sideMenu.section4.heading") }}
    </h3>
    <ul>
      <MenuListItem
        :name="t('sideMenu.section4.part1')"
        location="/#Organization-General"
        :selected="route.name === 'General'"
      />
      <MenuListItem
        :name="t('sideMenu.section4.part2')"
        location="/#Organization-Details"
        :selected="route.name === 'Details'"
      />
      <MenuListItem
        :name="t('sideMenu.section4.part3')"
        location="/#Organization-Projects"
        :selected="route.name === 'Organization-Projects'"
      />
      <MenuListItem
        :name="t('sideMenu.section4.part4')"
        location="/#Organization-Users"
        :selected="route.name === 'Organization-Users'"
      />
      <MenuListItem
        :name="t('sideMenu.section4.part5')"
        location="/#Organization-Categories"
        :selected="route.name === 'Organization-Categories'"
      />
      <MenuListItem
        :name="t('sideMenu.section4.part6')"
        location="/#Organization-Tags"
        :selected="route.name === 'Organization-Tags'"
      />
      <MenuListItem
        :name="t('sideMenu.section4.part7')"
        location="/#Organization-Groups"
        :selected="route.name === 'Organization-Groups'"
      />
    </ul>
  </nav>
</template>

<style scoped lang="scss">
@use "@material/list/evolution-mixins" as list-evolution-mixins;

@include list-evolution-mixins.core-styles;

h3 {
  width: unset;
}

ul {
  padding-inline-start: 16px;
}
</style>
