<script setup lang="ts">
import { toRefs } from "vue";
import RxMenu from "@/components/shared/RxMenu.vue";
import { useI18n } from "vue-i18n";

interface menuProps {
  toggle: boolean;
}

const props = withDefaults(defineProps<menuProps>(), {
  toggle: false,
});

const { toggle } = toRefs(props);

const { locale } = useI18n();

function selectLanguage(lang: string) {
  locale.value = lang;
  localStorage.setItem("i18next", lang);
}
</script>

<template>
  <RxMenu :toggle="toggle">
    <template #content>
      <!-- <li
        class="mdc-list-item"
        :class="{ selected: locale === 'nb' }"
        role="menuitem"
        @click="() => selectLanguage('nb')"
      >
        <span class="mdc-list-item__ripple"></span>
        <span class="mdc-list-item__text">Norsk</span>
      </li> -->
      <li
        class="mdc-list-item"
        :class="{ selected: locale === 'en' }"
        role="menuitem"
        @click="() => selectLanguage('en')"
      >
        <span class="mdc-list-item__ripple"></span>
        <span class="mdc-list-item__text">English</span>
      </li>
    </template>
  </RxMenu>
</template>

<style scoped lang="scss">
@use "@material/list/mdc-list";

.selected {
  background-color: var(--brand-primary-25);
}
</style>
