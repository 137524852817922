<script setup lang="ts">
import { MDCTopAppBar, MDCTopAppBarFoundation } from "@material/top-app-bar";
import { onMounted, ref } from "vue";
import { icon_language, icon_menu } from "@/components/icons";
import LanguageMenu from "@/components/language/LanguageMenu.vue";

const emit = defineEmits<{
  (e: "navigation_event"): void;
  (e: "clearChanges"): void;
}>();

const toggleLanguageMenu = ref(false);
const topAppBarElement = ref();

onMounted(() => {
  const topAppBar = new MDCTopAppBar(topAppBarElement.value);

  topAppBar.listen(MDCTopAppBarFoundation.strings.NAVIGATION_EVENT, () => {
    emit("navigation_event");
  });
});
</script>

<template>
  <header ref="topAppBarElement" class="mdc-top-app-bar app-bar">
    <div class="mdc-top-app-bar__row">
      <section
        class="mdc-top-app-bar__section mdc-top-app-bar__section--align-start"
      >
        <div
          class="mdc-top-app-bar__navigation-icon pointer icon-container"
          v-html="icon_menu"
          aria-label="Open navigation menu"
        ></div>
        <span class="mdc-top-app-bar__title">FAQ</span>
      </section>
      <section
        class="mdc-top-app-bar__section mdc-top-app-bar__section--align-end"
        role="toolbar"
      >
        <div class="mdc-menu-surface--anchor">
          <div
            class="mdc-top-app-bar__navigation-icon pointer icon-container"
            v-html="icon_language"
            aria-label="Open language menu"
            @click="toggleLanguageMenu = !toggleLanguageMenu"
          ></div>
          <LanguageMenu :toggle="toggleLanguageMenu" />
        </div>
      </section>
    </div>
  </header>
</template>

<style scoped lang="scss">
@use "@material/icon-button";
@use "@material/top-app-bar/mdc-top-app-bar";

@media only screen and (min-width: 769px) {
  .app-bar {
    position: absolute;
  }

  // Only apply this style if below top app bar.
  .mdc-top-app-bar {
    z-index: 7;
  }
}

@media only screen and (max-width: 768px) {
}
</style>
