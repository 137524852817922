<script setup lang="ts">
import { onMounted, ref, toRefs, watch } from "vue";
import { MDCMenu, Corner } from "@material/menu";
import { MDCMenuSurfaceFoundation } from "@material/menu-surface";

interface menuProps {
  toggle: boolean;
}

const props = withDefaults(defineProps<menuProps>(), {
  toggle: false,
});

const { toggle } = toRefs(props);

const menuRef = ref();
const menu = ref<MDCMenu>();

const open = ref(false);

onMounted(() => {
  menu.value = new MDCMenu(menuRef.value);

  menu.value.listen(
    MDCMenuSurfaceFoundation.strings.CLOSED_EVENT,
    () => (open.value = false)
  );
  menu.value.listen(
    MDCMenuSurfaceFoundation.strings.OPENED_EVENT,
    () => (open.value = true)
  );

  menu.value.setAnchorCorner(Corner.BOTTOM_START);
  menu.value.setAnchorMargin({
    bottom: 32,
  });
});

watch(toggle, () => {
  console.log(menu.value?.open);
  if (!menu.value) return;
  menu.value.open = !open.value;
});
</script>

<template>
  <div ref="menuRef" class="mdc-menu mdc-menu-surface">
    <ul
      class="mdc-list"
      role="menu"
      aria-hidden="true"
      aria-orientation="vertical"
      tabindex="-1"
    >
      <slot name="content"></slot>
    </ul>
  </div>
</template>

<style scoped lang="scss">
@use "@material/list/mdc-list";
@use "@material/menu-surface/mdc-menu-surface";
@use "@material/menu/mdc-menu";
</style>
