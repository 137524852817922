<script setup lang="ts">
import { MDCDrawer } from "@material/drawer";
import { onMounted, ref, watch } from "vue";
import { icon_home } from "@/components/icons";
import { useAppStore } from "@/appStore";
import SideMenuItems from "@/components/layout/SideMenuItems.vue";
import TopBarHeader from "@/components/layout/TopBarHeader.vue";

const drawerElement = ref();
const drawer = ref();

const { screenSize } = useAppStore();

onMounted(() => {
  setTimeout(setSidebarStyles, 0);
});

function setSidebarStyles() {
  if (screenSize.value === 1) {
    drawerElement.value.classList.replace(
      "mdc-drawer--dismissible",
      "mdc-drawer--modal"
    );
    drawerElement.value.classList.remove("mdc-top-app-bar--fixed-adjust");
  } else {
    drawerElement.value.classList.replace(
      "mdc-drawer--modal",
      "mdc-drawer--dismissible"
    );
    drawerElement.value.classList.add("mdc-top-app-bar--fixed-adjust");
  }
  setTimeout(() => {
    drawer.value = new MDCDrawer(drawerElement.value);
    drawer.value.open = true;
  }, 0);
}

watch(screenSize, () => {
  setSidebarStyles();
});

function toggleDrawer() {
  drawer.value.open = !drawer.value.open;
}
</script>

<template>
  <TopBarHeader @navigation_event="toggleDrawer" />

  <aside
    ref="drawerElement"
    class="mdc-drawer mdc-drawer--dismissible mdc-top-app-bar--fixed-adjust"
  >
    <div class="mdc-drawer__header">
      <a
        class="home-icon pointer icon-container large dark"
        v-html="icon_home"
        aria-label="Home"
        href="/#"
      ></a>
    </div>
    <div class="mdc-drawer__content">
      <SideMenuItems />
    </div>
  </aside>

  <div v-if="screenSize === 1" class="mdc-drawer-scrim"></div>
  <div class="mdc-drawer-app-content mdc-top-app-bar--fixed-adjust">
    <main class="main-content">
      <slot name="main"></slot>
    </main>
  </div>
</template>

<style scoped lang="scss">
@use "@material/icon-button";
@use "@material/top-app-bar/mdc-top-app-bar";
@use "@material/drawer";

@include drawer.core-styles;
@include drawer.dismissible-core-styles;
@include drawer.modal-core-styles;

.home-icon {
  margin-block-start: 1em;
  display: flex;
  flex-direction: row;
  align-items: center;
}

@media only screen and (min-width: 769px) {
  .mdc-drawer-app-content {
    flex: auto;
    overflow: auto;
    position: relative;
  }

  .main-content {
    overflow: auto;
    height: calc(100vh - var(--top-bar-height) - 2em);
    background-color: var(----brand-primary-75);
    padding: 1em;
  }

  .app-bar {
    position: absolute;
  }

  // Only apply this style if below top app bar.
  .mdc-top-app-bar {
    z-index: 7;
  }
}

@media only screen and (max-width: 768px) {
}
</style>
