export const sideMenu = {
  section1: {
    heading: "Login & Home page",
    part1: "Login",
    part2: "Folders",
    part3: "Projects",
  },
  section2: {
    heading: "Project Features",
    intro: "Intro",
    part1: "Map Icons",
    part2: "General Menu Icons",
    part3: "Annotations",
    part4: "Project-Folders",
  },
  section3: {
    heading: "Reporting",
    part1: "Intro",
    part2: "Creating a report",
  },
  section4: {
    heading: "Organization",
    part1: "General",
    part2: "Details",
    part3: "Projects",
    part4: "Users",
    part5: "Categories",
    part6: "Tags",
    part7: "Groups",
  },
};
