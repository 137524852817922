import { sample } from "./sample";
import { shared } from "./shared";
import { sideMenu } from "./sideMenu";
import { login } from "./login";
import { folders } from "./folders";
import { projects } from "./projects";
import { mapIcons } from "./mapIcons";
import { generalMenuIcons } from "./generalMenuIcons";
import { annotations } from "./annotations";
import { organization } from "./organization";
import { reportingIntro } from "./reportingIntro";
import { createReport } from "./createReport";
import { projectFolders } from "./projectFolders";
export const en = {
  sample,
  shared,
  sideMenu,
  login,
  folders,
  projects,
  mapIcons,
  generalMenuIcons,
  annotations,
  organization,
  reportingIntro,
  createReport,
  projectFolders
};
