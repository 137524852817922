import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import Home from "../components/Home.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/sample",
    name: "sample",
    component: () => import("../components/helpPages/Sample.vue"),
  },
  {
    path: "/Login",
    name: "Login",
    component: () => import("../components/helpPages/Login.vue"),
  },
  {
    path: "/Folders",
    name: "Folders",
    component: () => import("../components/helpPages/Folders.vue"),
  },
  {
    path: "/Projects",
    name: "Projects",
    component: () => import("../components/helpPages/Projects.vue"),
  },
  {
    path: "/ProjectsIntro",
    name: "ProjectsIntro",
    component: () => import("../components/helpPages/ProjectsIntro.vue"),
  },
  {
    path: "/MapIcons",
    name: "MapIcons",
    component: () => import("../components/helpPages/MapIcons.vue"),
  },
  {
    path: "/GeneralMenuIcons",
    name: "GeneralMenuIcons",
    component: () => import("../components/helpPages/GeneralMenuIcons.vue"),
  },
  {
    path: "/Annotations",
    name: "Annotations",
    component: () => import("../components/helpPages/Annotations.vue"),
  },
  {
    path: "/Project-Folders",
    name: "Project-Folders",
    component: () => import("../components/helpPages/Project-Folders.vue"),
  },
  {
    path: "/ReportingIntro",
    name: "ReportingIntro",
    component: () => import("../components/helpPages/ReportingIntro.vue"),
  },
  {
    path: "/CreateReport",
    name: "CreateReport",
    component: () => import("../components/helpPages/CreateReport.vue"),
  },
  {
    path: "/Organization-General",
    name: "Organization-General",
    component: () => import("../components/helpPages/Organization-General.vue"),
  },
  {
    path: "/Organization-Details",
    name: "Organization-Details",
    component: () => import("../components/helpPages/Organization-Details.vue"),
  },
  {
    path: "/Organization-Projects",
    name: "Organization-Projects",
    component: () => import("../components/helpPages/Organization-Projects.vue"),
  },
  {
    path: "/Organization-Users",
    name: "Organization-Users",
    component: () => import("../components/helpPages/Organization-Users.vue"),
  },
  {
    path: "/Organization-Categories",
    name: "Organization-Categories",
    component: () => import("../components/helpPages/Organization-Categories.vue"),
  },
  {
    path: "/Organization-Tags",
    name: "Organization-Tags",
    component: () => import("../components/helpPages/Organization-Tags.vue"),
  },
  {
    path: "/Organization-Groups",
    name: "Organization-Groups",
    component: () => import("../components/helpPages/Organization-Groups.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(import.meta.env.BASE_URL),
  routes,
});

export default router;
